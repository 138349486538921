import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import img from "../assets/images/web-design.jpg";

export default function WebDesign() {
	return (
		<div className="d-flex justify-content-center py-5">
			<div className="section-container pt-5">
				<h2 className="font-weight-bold text-uppercase py-3">
					Нашите Услуги за Уеб Дизайн
				</h2>
				<Helmet>
					<title>Нашите Услуги за Уеб Дизайн</title>
					<meta
						name="description"
						content="В Инстант Сълюшънс сме тук, за да създадем уебсайт,
						който не просто представя твоята компания, а го прави
						със стил и елегантност. Специализираме се в индивидуален
						подход, гарантирайки, че всеки проект, който създаваме,
						е уникален и отразява ценностите на твоя бранд."
					/>
				</Helmet>
				<img src={img} className="page-image-container" alt="web-design-and-development" />
				<div className="text-left justify-content-center">
					<p>
						В Инстант Сълюшънс сме тук, за да създадем уебсайт,
						който не просто представя твоята компания, а го прави
						със стил и елегантност. Специализираме се в индивидуален
						подход, гарантирайки, че всеки проект, който създаваме,
						е уникален и отразява ценностите на твоя бранд.
					</p>
					<p>
						Нашата цел е не просто да предоставим визуално
						привлекателен дизайн, но и да създадем потребителско
						преживяване, което твоите клиенти няма да забравят. За
						нас е от изключително значение да разберем твоите цели и
						нужди преди да преминем към създаването на
						персонализиран уеб проект, който да ти помогне да
						постигнеш успех.
					</p>
					<p>
						Независимо дали стартираш нов бизнес, обновяваме
						съществуващ уебсайт или търсиш ефективни онлайн решения,
						ние сме тук, за да те подкрепим. Съчетаваме
						креативността си с технологичните иновации, за да
						предложим уеб дизайн, който не само привлича внимание,
						но и генерира реални резултати.
					</p>
					<p>
						Ние вярваме, че модерният уеб дизайн създадава устойчиво
						и привлекателно онлайн присъствие. С нас получаваш не
						само стилен формат на уебсайта, но и функционалност,
						която подчертава уникалността на твоя бранд и предлага
						добро потребителско преживяване.
					</p>
					<p>
						Нашият екип изготвя всеки детайл с внимание, за да
						изработи уебсайт, който не само изглежда добре, но и
						работи ефективно. Използваме последните тенденции в уеб
						технологиите, за да предоставим модерни и иновативни
						решения за твоите онлайн нужди.
					</p>
					<p>
						При нас не получаваш просто уебсайт, а цялостно решение,
						което поддържа твоя бизнес. Изслушваме твоите цели и
						стремежи, преди да пристъпим към проектирането. Всеки
						етап от процеса на разработка е насочен към
						удовлетворяване на потребностите на твоя бизнес.
					</p>
					<p>
						Нашата философия е изчистени, минималистични и
						функционални дизайни, които внушават доверие. Използваме
						цветове и графика в синхрон с твоя бранд, за да създадем
						уебсайт, който не само отговаря на изискванията ти, но и
						ги допълва.
					</p>
					<p>
						Зад всяко уеб решение стои ангажиментът ни към
						клиентите. Вярваме в прозрачната комуникация и
						сътрудничество през целия процес на разработка. Това ни
						позволява да вграждаме твоите идеи и визия във всеки
						детайл от уеб проекта, което гарантира твоето пълно
						удовлетворение.
					</p>
					<p>
						Приемаме предизвикателствата и се стремим да предоставим
						персонализирани уеб решения, които да насърчават растежа
						на твоия бизнес. Нашата цел е да създадем уебсайт, който
						не само привлича посетители, но и създава клиенти.
					</p>
				</div>
				<div className="d-flex justify-content-center mt-4">
					<Link to={"/contact-us"}>
						<button className="btn-lg my-2">
							Свържете се с нас
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
