import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import img from "../assets/images/workspace.jpg";

export default function WebSolutions() {
	return (
		<div className="d-flex justify-content-center py-5">
			<div className="section-container pt-5">
				<h2 className="font-weight-bold text-uppercase py-3">
					Какво правим?
				</h2>
				<Helmet>
					<title>Какво правим?</title>
					<meta
						name="description"
						content="Инстант Сълюшънс е твоят партньор в света на цифровите решения. Ние сме
						фокусирани върху създаването на уебсайтове, които не само
						впечатляват визуално, но и предоставят стратегически резултати за
						твоя бизнес. Нашата цел е да направим онлайн присъствието ти
						неотразимо и ефективно."
					/>
				</Helmet>
				<img src={img} className="page-image-container" alt="what-we-do" />
				<div className="text-left justify-content-center">
					<p>
						Инстант Сълюшънс е твоят партньор в света на цифровите
						решения. Ние сме фокусирани върху създаването на
						уебсайтове, които не само впечатляват визуално, но и
						предоставят стратегически резултати за твоя бизнес.
						Нашата цел е да направим онлайн присъствието ти
						неотразимо и ефективно.
					</p>
					<p>
						В света на бързо развиващите се технологии, ние не
						просто следваме тенденциите, но ги създаваме. Нашите
						експерти в областта на уеб дизайна и разработка са
						винаги в крак с последните иновации, гарантирайки, че
						твоят уебсайт не само изглежда модерно, но и използва
						най-новите технологични възможности.
					</p>
					<p>
						Зад всяка уеб страница, която създаваме, стои
						индивидуален подход. Разбираме, че всяка компания е
						уникална и има свои изисквания. Затова, вместо шаблонни
						решения, ние създаваме персонализирани уебсайтове, които
						отразяват твоите уникални потребности и ценности.
					</p>
					<p>
						Всеки проект, който поемаме, е повод за вдъхновение и
						креативност. Ние вярваме, че добре изграденият уебсайт
						не е просто инструмент за представяне, а истинско
						изкуство, което привлича вниманието и задържа интереса
						на потребителите. Работим усилено, за да направим твоя
						бранд незабравим.
					</p>
					<p>
						Когато избираш Инстант Сълюшънс, избираш екип от
						ангажирани професионалисти. Ние вярваме в силата на
						сътрудничеството и даваме всичко от себе си, за да
						постигнем успеха на твоя проект. Твоят успех е нашият
						стимул за успех.
					</p>
					<p>
						Ние сме не просто дигитална агенция, а твоят
						технологичен стратегически съветник. Работим със
						задачата да трансформираме идеите ти в реалност,
						използвайки иновациите в технологиите, за да направим
						твоят бизнес по-ефективен и успешен в онлайн
						пространството.
					</p>
					<p>
						Нашата мисия е да не просто създадем уебсайт, а да
						построим цялостно цифрово преживяване. Интегрираме
						дизайн, функционалност и стратегия, за да създадем уеб
						решение, което не само изпълнява, но и надхвърля
						очакванията ти и на твоите потенциални клиенти.
					</p>
					<p>
						Когато работиш с нас, не само получаваш уебсайт, но и
						партньорство, което продължава. След внедряването на
						проекта, ние сме тук, за да осигурим поддръжка и да ти
						помагаме с развитието на онлайн присъствието ти. Ние
						вярваме в дългосрочните отношения и успеха, който можем
						да постигнем заедно.
					</p>
					<p>
						Защото в света на дигиталните възможности, Инстант
						Сълюшънс е твоят ключ към успеха. Свържи се с нас днес и
						направи първата стъпка към изграждането на силно и
						ефективно онлайн присъствие за твоя бизнес.
					</p>
				</div>
				<div className="d-flex justify-content-center mt-4">
					<Link to={"/contact-us"}>
						<button className="btn-lg my-2">
							Свържете се с нас
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
