import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import img from "../assets/images/the-future-is-here.jpg";

export default function WebSolutions() {
	return (
		<div className="d-flex justify-content-center py-5">
			<div className="section-container pt-5">
				<h2 className="font-weight-bold text-uppercase py-3">
					Уеб решения за теб
				</h2>
				<Helmet>
					<title>Уеб решения за теб</title>
					<meta
						name="description"
						content="Ние вярваме, че успешният уебсайт е повече от просто визуален
						елемент – той е инструмент за постигане на бизнес цели. С внимание
						към детайла, ние проектираме и разработваме уебсайтове, които не
						само привличат вниманието на потребителите, но и генерират реални
						резултати."
					/>
				</Helmet>
				<img src={img} className="page-image-container" alt="web-solutions-for-you" />
				<div className="text-left justify-content-center">
					<p>
						Ние вярваме, че успешният уебсайт е повече от просто
						визуален елемент – той е инструмент за постигане на
						бизнес цели. С внимание към детайла, ние проектираме и
						разработваме уебсайтове, които не само привличат
						вниманието на потребителите, но и генерират реални
						резултати.
					</p>
					<p>
						За нас е важно да не предоставяме просто услуги, а да
						създаваме успешни партньорства. Подходящото уеб решение
						е ключът за постигане на твоите цели и ние сме тук, за
						да ти помогнем за изграждането на този ключов елемент за
						твоя бизнес успех.
					</p>
					<p>
						Нашата екипна работа и посвещение гарантират уебсайт,
						който не само отговаря на текущите нужди на бизнеса, но
						е и гъвкав и с опция за надграждане в бъдеще. Съчетаваме
						креативността с технологичните възможности, за да
						предоставим уникално и високотехнологично уеб решение.
					</p>
					<p>
						Изграждането на онлайн присъствие не трябва да бъде
						сложно. С нашия подход, се стремим да направим процеса
						лесен и прозрачен. Предоставяме ясна комуникация и
						редовен ъпдейт по време на разработката, за да бъдеш
						винаги в течение с напредъка в изработката на твоя
						уебсайт.
					</p>
					<p>
						Ние сме горди от нашата репутация за отлично обслужване
						на клиенти. Удовлетвореността на нашите партньори е наш
						приоритет, и ние сме тук, за да те подкрепим не само по
						време на проекта, но и в бъдеще, когато твоят бизнес се
						развива.
					</p>
					<p>
						Когато избирате Инстант Сълюшънс, избирате партньорство,
						изградено върху доверие и качество. Ние вярваме в
						дългосрочните отношения и сме готови да помогнем във
						всеки етап от развитието на твоя онлайн бизнес.
					</p>
					<p>
						Ние разбираме, че всеки бизнес е уникален и има свои
						специфични изисквания. Поради тази причина, нашият
						подход е персонализиран и адаптиран към конкретните
						бизнес нужди. Твоят успех е наш успех, и ние сме
						ангажирани да предоставим уебсайт, който отразява
						уникалността и ценностите на твоя бранд.
					</p>
					<p>
						Индустрията на уеб технологиите е в постоянно развитие,
						и ние сме тук, за да предоставим не само модерни и
						устойчиви решения. Следим последните тенденции и
						иновации, за да гарантираме, че твоят уебсайт е в крак с
						времето и готов за бъдещите предизвикателства.
					</p>
					<p>
						Фирменият уебсайт е визитката на твоя бизнес. С нашата
						помощ, той не само ще привлече клиенти, но и ще ги убеди
						в професионализма и качеството на услугите или
						продуктите, които твоят бизнес предлага.
					</p>
					<p>
						Очакваме с нетърпение да работим с теб и да създадем уеб
						решение, което ще помогне да доминираш в онлайн
						пространството и да постигнеш бизнес целите си.
					</p>
					<p>
						Свържете се с нас, за да получите подробна информация за
						нашите услуги.
					</p>
				</div>

				<div className="d-flex justify-content-center mt-4">
					<Link to={"/contact-us"}>
						<button className="btn-lg my-2">
							Свържете се с нас
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
