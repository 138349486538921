import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
	return (
		<footer className="width-100 bg-dark text-white">
			<Container className="pl-0 pr-0 footer-container">
				<Row className="justify-content-around align-items-center">
					<Col className="justify-content-center mt-3">
						<Row className="justify-content-center">
							<Col>
								<p className="text-align-center">
									&copy; 2024 Instant Solutions Ltd. All
									rights reserved.
								</p>
							</Col>
						</Row>
					</Col>
					<Col className="">
						<div className="d-flex justify-content-end">
							<a
								href="https://www.facebook.com/profile.php?id=61559910524558"
								target="_blank"
								rel="noopener"
								className="text-white me-4"
							>
								<FaFacebook fontSize="20px" />
							</a>
							<a
								href="https://www.instagram.com/instant.solutions.bg/"
								target="_blank"
								rel="noopener"
								className="text-white me-4"
							>
								<FaInstagram fontSize="20px" />
							</a>
							<a
								href="https://www.linkedin.com/company/instant-solutionsltd/"
								target="_blank"
								rel="noopener"
								className="text-white"
							>
								<FaLinkedin fontSize="20px" />
							</a>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
