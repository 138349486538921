import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import CompanyWebsiteDevelopment from "../components/CompanyWebsiteDevelopment";
import { Helmet } from "react-helmet";
import PersonalWebsiteDevelopment from "../components/PersonalWebsiteDevelopment";
import OnlineStoreDevelopment from "../components/OnlineStoreDevelopment";

export default function Services() {
	// eslint-disable-next-line
	const [animateWebSolutions, setAnimateWebSolutions] = useState(false);

	return (
		<>
			<Container fluid className="second-color text-white py-5 pb-4">
				<Helmet>
					<title>Нашите услуги</title>
					<meta
						name="description"
						content="Научете повече за услугите, които предлагаме - изработка на корпоративен уебсайт и онлайн магазин. 
				Използваме както платформи като WordPress, OpenCart, Joomla, също така можем да изградим изцяло персонален уебсайт, написан от нулата."
					/>
				</Helmet>
				<Row className="justify-content-center mt-5">
					<Col md="8">
						<h2 className="font-size-5xl text-center pt-3">
							Нашите услуги
						</h2>
					</Col>
				</Row>
			</Container>

			<Container fluid>
				<Row
					className={`slide-in ${
						animateWebSolutions ? "active" : ""
					} first-color`}
				>
					<CompanyWebsiteDevelopment />
				</Row>
				<Row
					className={`slide-in ${
						animateWebSolutions ? "active" : ""
					}`}
				>
					<PersonalWebsiteDevelopment />
				</Row>
				<Row
					className={`slide-in ${
						animateWebSolutions ? "active" : ""
					} first-color`}
				>
					<OnlineStoreDevelopment />
				</Row>
			</Container>

			<Container fluid className="py-5 justify-content-center">
				<Row className="justify-content-center">
					<Col md="8" lassName="justify-content-center">
						<h2 className="font-size-2xl font-weight-bold text-uppercase text-center">
							Не чакайте повече
						</h2>
						<Container className="justify-content-center">
							<Row className="justify-content-center">
								<Col md="8" className="text-center">
									{" "}
									{/* Updated class */}
									<p className="text-center">
										Свържете се с нас, за да получите своя
										завладяващ уебсайт за Вашия бизнес.
									</p>
									<Link to="/contact-us">
										<button className="btn-lg mt-4">
											Свържете се с нас
										</button>
									</Link>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
		</>
	);
}
