import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import img from "../assets/images/people-discuss-website-project.jpg";

export default function PersonalContact() {
	return (
		<div className="d-flex justify-content-center py-5">
			<div className="section-container pt-5">
				<h2 className="font-weight-bold text-uppercase py-3">Личен контакт</h2>
				<Helmet>
					<title>Личен контакт</title>
					<meta
						name="description"
						content="При нас ще откриеш екип от професионалисти, които се стремят към истинско разбиране на твоите
						нужди. С нас ще имаш личен контакт - един и същи човек, който ще комуникира целия процес по изграждане на уебсайта."
					/>
				</Helmet>
				<img src={img} className="page-image-container" alt="personal-contact" />
				<div className="text-left justify-content-center">
					<p>
						При нас ще откриеш екип от професионалисти, които се стремят към истинско разбиране на твоите
						нужди. С нас ще имаш личен контакт - един и същи човек, който ще комуникира целия процес по изграждане на уебсайта.
					</p>
					<p>
						Вярваме в силата на личния контакт и доверието, което той генерира.
						Затова предоставяме персонализиран и индивидуален подход към всеки
						проект. Твоите идеи и предпочитания са от съществено значение за
						нас, и ще работим усърдно, за да ги вплетем във всяко детайлно решение
						на проекта.
					</p>
					<p>
						Нашата мисия е не просто да изградим уебсайт, а да създадем
						дълготрайно партньорство с теб. След като твоят уебсайт е готов, ние
						продължаваме да сме на твое разположение за всякаква поддръжка и
						развитие, които са необходими.
					</p>
					<p>
						Индустрията на уеб технологиите се променя бързо, но с нас винаги ще
						бъдеш в час с предизвикателствата. Ще ти предоставим модерни и
						иновативни решения, които да отговарят на нуждите на твоя бизнес и
						да го направят изключителен в онлайн пространството.
					</p>
					<p>
						Във всеки момент, когато имаш въпроси, нашите
						експерти са на твое разположение. Личният контакт включва редовни
						срещи и персонализиране на всяко решение. За нас, взаимодействието с
						теб не е просто стъпка от проекта, а възможност да развиваме твоя
						бизнес заедно.
					</p>
					<p>
						Ние вярваме в това, че успешното изграждане на уебсайт се основава
						не само на технологичните умения, но и на изграждането на
						дълготрайни отношения. Ще бъдеш в постоянен контакт с нас, и всеки
						напредък ще бъде споделен и обсъден лично с теб, за да се уверим, че
						твоят уебсайт отговаря на всички твои очаквания и цели.
					</p>
					<p>
						В нашата работа се стремим не само да изградим функционален уебсайт,
						но и да изградим трайни връзки с нашите клиенти. Личният ти контакт
						с нас не спира със завършването на проекта. Ние оставаме на твое
						разположение, готови да реагираме на всяко твое запитване и да
						предоставим необходимата поддръжка.
					</p>
					<p>
						Всеки уебсайт, който създаваме, е уникален, като отразява
						индивидуалността и целите на клиента. При нас няма готови решения -
						всеки проект е изцяло персонализиран, за да отговаря на уникалните
						изисквания и очаквания.
					</p>
					<p>
						За нас уебсайтът не е просто виртуално пространство, а инструмент за
						постигане на успех и взаимодействие с клиентите. Искаме да бъдеш не
						просто клиент, а партньор, с когото да сътрудничим за дългосрочен
						успех и устойчив растеж в онлайн средата.
					</p>
					<p>
						Нека заедно изградим не просто уебсайт, а цялостно
						дигитално преживяване, което да впечатли и задоволи твоите клиенти.
						Свържи се с нас и започни пътешествието към успешно онлайн
						присъствие!
					</p>
					<div className="d-flex justify-content-center mt-4">
						<Link to={"/contact-us"}>
							<button className="btn-lg my-2">Свържете се с нас</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
