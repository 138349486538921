import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import img from "../assets/images/responsive-web-design.jpg";	

export default function WeKnowWhatWeDo() {
	return (
		<div className="d-flex justify-content-center py-5">
			<div className="section-container pt-5">
				<h2 className="font-weight-bold text-uppercase py-3">
					Знаем какво правим
				</h2>
				<Helmet>
					<title>Знаем какво правим</title>
					<meta
						name="description"
						content="Всяко едно наше действие и доработка по сайт е добре обмислено и съобразено с нуждите на клиента и модерните решения в областта на уеб разработката."
					/>
				</Helmet>
				<img src={img} className="page-image-container" alt="we-know-what-we-do" />
				<div className="text-left justify-content-center">
					<p>
						В Инстант Сълюшънс не създаваме просто уебсайтове – ние
						владеем изкуството на онлайн присъствието. Знаем какво
						правим и сме горди, че винаги в час с най-новите
						тенденции в света на уеб разработката.
					</p>
					<p>
						В свят, където технологичните тенденции се променят със
						скоростта на светлината, ние оставаме стъпка пред
						всички. Нашите специалисти по уеб дизайн и програмиране
						постоянно усъвършенстват своите умения и знания. С
						увереност можем да заявим: знаем какво правим и го
						правим с увереност. Когато избирате Инстант Сълюшънс,
						избирате екип, който знае какво прави и как да ви
						предостави най-доброто.
					</p>
					<p>
						Знанието и експертизата на нашия екип не са статични –
						те се развиват и растат с всеки нов проект. Всеки член
						на нашия екип е посветен на постоянното учене и
						приспособяване към променящата се технологична среда.
						Така че, когато работите с нас, работите с екип, който
						постоянно се развива и усъвършенства.
					</p>
					<p>
						За нас, знанието не е просто инструмент, а е стил на
						живот. Стремим се да бъдем винаги на гребена на
						технологичните иновации, за да предложим уеб проекти,
						които не само отговарят на текущите изисквания, но и
						допълват очакванията. В наше лице имаш партньор, който
						знае какво прави.
					</p>
					<p>
						Нашият екип не просто разработва уебсайтове – той твори
						цялостни онлайн преживявания. С индивидуален подход и
						умения, ние знаем как да впечатлим твоята аудитория. За
						нас, всяка линия код е възможност да докоснем
						перфекцията и да докажем, че знаем какво правим.Нашата
						ангажираност към постоянно усъвършенстване не е просто
						задължение – това е част от нашата ДНК. Всяко ново
						предизвикателство е възможност да докажем, че сме наясно
						с последните тенденции и знаем как да ги приложим.
					</p>
					<p>
						В нашия екип, креативността и техническата готовност се
						преплитат. Ние сме гъвкави и адаптивни, готови да
						реагираме на всяко изискване и предизвикателство. За
						нас, усъвършенстването е процес, който никога не спира,
						защото знаем, че само така можем да предложим
						най-доброто.
					</p>
					<p>
						Защо избираме да се наричаме "Инстант Сълюшънс"? Защото
						знаем, че всяко предизвикателство е само възможност за
						решение, всяка загатка има свое разрешение. С нас знаеш,
						че работиш с екип, който точно знае какво прави и как да
						превърне твоя уебсайт в добро клиентско преживяване.
					</p>
				</div>
				<div className="d-flex justify-content-center mt-4">
					<Link to={"/contact-us"}>
						<button className="btn-lg my-2">
							Свържете се с нас
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
