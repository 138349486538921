import "./App.css";
import Footer from "./components/Footer";
import Home from "./pages/home";
import Navigation from "./components/Navigation";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/about";
import ContactUs from "./pages/contactUs";
import WebSolutions from "./pages/webSolutions";
import WhatWeDo from "./pages/whatWeDo";
import WebDesign from "./pages/webDesign";
import PersonalContact from "./pages/personalContact";
import CustomWebsites from "./pages/customWebsites";
import WeKnowWhatWeDo from "./pages/weKnowWhatWeDo";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./pages/services";
import CompanyWebsiteDevelopment from "./pages/companyWebsiteDevelopment";
import PersonalWebsiteDevelopment from "./pages/personalWebsiteDevelopment";
import OnlineStoreDevelopment from "./pages/onlineStoreDevelopment";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Navigation />
			<Helmet>
				<title>Изработка на Уеб Сайтове</title>
				<meta
					name="description"
					content="Предлагаме изработка на корпоративен уебсайт, личен уебсайт, онлайн магазин. Инстант Сълюшънс съдейства изцяло за изграждане на твоето онлайн присъствие."
				/>
				<meta
					name="keywords"
					content="Сайт, Уебсайт, Онлайн магазин, Уеб, Интернет присъствие, Изработка на уебсайт"
				/>
			</Helmet>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route path="/services" element={<Services />} />
				<Route path="/about" element={<About />} />
				<Route path="/contact-us" element={<ContactUs />} />
				<Route path="/web-solutions" element={<WebSolutions />} />
				<Route path="/what-we-do" element={<WhatWeDo />} />
				<Route path="/web-design" element={<WebDesign />} />
				<Route path="/personal-contact" element={<PersonalContact />} />
				<Route path="/custom-websites" element={<CustomWebsites />} />
				<Route
					path="/we-know-what-we-do"
					element={<WeKnowWhatWeDo />}
				/>
				<Route
					path="/company-website-development"
					element={<CompanyWebsiteDevelopment />}
				/>
				<Route
					path="/personal-website-development"
					element={<PersonalWebsiteDevelopment />}
				/>
				<Route
					path="/online-store-development"
					element={<OnlineStoreDevelopment />}
				/>
			</Routes>
			<Footer />
		</BrowserRouter>
	);
}

export default App;
