import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import img from "../assets/images/online-store-development.jpg";
import { useInView } from "react-intersection-observer";

const OnlineStoreDevelopment = () => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.5,
	});

	useEffect(() => {
		// Add any additional logic you want to run when the component is in view
	}, [inView]);

	return (
		<Container fluid className="py-5 section-container">
			<Row className="justify-content-center image-container">
				<Col lg="6" className="pr-5 justify-content-center">
					<h3 className="font-weight-bold text-uppercase pt-3 pb-4 mb-0">
						Изработка на Онлайн Магазин
					</h3>
					<p>
						Онлайн магазинът е лесен начин за разширяване
						на твоя бизнес и достигане до повече клиенти, като
						предлага удобна платформа за продажби и управление на
						продукти. Ние създаваме онлайн магазини с модерен дизайн
						и функционалност, които улесняват
						пазаруването и увеличават продажбите.
					</p>
					<Link to={"/online-store-development"}>
						<button className="btn-lg mt-4">Научете повече</button>
					</Link>
				</Col>
				<Col lg="6">
					<div
						ref={ref}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							transition:
								"transform 1s ease-in-out, opacity 1s ease-in-out",
							opacity: inView ? 1 : 0,
						}}
					>
						<Image
							src={img}
							rounded
							alt="company-website-development"
							style={{ maxHeight: "300px", objectFit: "cover" }}
						/>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default OnlineStoreDevelopment;
