import { Col, Container, Image, Row } from "react-bootstrap";
import img from "../assets/images/the-future-is-here.jpg";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

export default function WebSolutionsForYou() {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.5,
	});

	useEffect(() => {
		if (inView) {
			// Add any additional logic you want to run when the component is in view
		}
	}, [inView]);

	return (
		<Container fluid className="py-5 justify-content-center section-container">
			<Row className="justify-content-center image-container">
				<Col lg="6" className="pr-5 justify-content-center">
					<h3 className="font-weight-bold text-uppercase pb-4 mb-0">
						Уеб решения за теб
					</h3>
					<div className="mb-3">
						<p className="fs-sm">
							Инстант Сълюшънс е твоето предпочитано място за иновативни уеб решения
							и дигитален успех. Като дигитална агенция, ние сме
							посветени на изграждането на онлайн присъствие, което не само представя
							бизнеса ти, но и го издигат на нови висоти в интернет пространството. Нашата мисия е да помогнем да бъдете
							разпознавани в дигиталния свят, като създадем за уебсайт,
							който отговаря на твоите уникални нужди и цели.
						</p>
					</div>
					<Link to={"/web-solutions"}>
						<button className="btn-lg mt-4">
							Научете повече
						</button>
					</Link>
				</Col>
				<Col lg="6">
					<div
						ref={ref}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							transition: "transform 1s ease-in-out, opacity 1s ease-in-out",
							opacity: inView ? 1 : 0,
						}}
					>
						<Image
							src={img}
							rounded
							alt="the-future-is-here"
							style={{ maxHeight: "300px", objectFit: "cover" }}
						/>
					</div>
				</Col>
			</Row>
		</Container>
	);
}
