import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import img from "../assets/images/web-designer-office-working.jpg";

export default function CustomWebsites() {
	return (
		<div className="d-flex justify-content-center py-5">
			<div className="section-container pt-5">
				<h2 className="font-weight-bold text-uppercase py-3">
					Нашите уебсайтове са персонални
				</h2>
				<Helmet>
					<title>Персонални уебсайтове</title>
					<meta
						name="description"
						content="В Инстант Сълюшънс съчетаваме стил и функционалност, за да създадем
						уебсайт, който не просто представя вашия бизнес, но го прави с
						почерк и изтънченост. Нашите уебсайтове са плод на индивидуален
						подход, отразявайки точно вашите уникални ценности и идеи."
					/>
				</Helmet>
				<img src={img} className="page-image-container" alt="custom-websites" />
				<div className="text-left justify-content-center">
					<p>
						В Инстант Сълюшънс съчетаваме стил и функционалност, за да създадем
						уебсайт, който не просто представя вашия бизнес, но го прави с
						почерк и изтънченост. Нашите уебсайтове са плод на индивидуален
						подход, отразявайки точно вашите уникални ценности и идеи.
					</p>
					<p>
						За нас, създаването на уебсайт не е просто задача, а е възможност да
						изразим креативността си и да предоставим на клиентите ни нещо
						по-специално. С дългогодишен опит в разработката на персонализирани
						уебсайтове, гарантираме високо качество и индивидуален подход към
						всеки проект.
					</p>
					<p>
						Твоето онлайн присътствие трябва да бъде уникално, точно като твоя
						бизнес. С Инстант Сълюшънс, ще откриеш партньор, който не само слуша
						твоите изисквания, но и ги възпроизвежда във всеки детайл от
						твоя уеб проект.
					</p>
					<p>
						Не се задоволяваме с обикновени уебсайтове. Специализираме се в
						създаването на онлайн пространство, което отразява индивидуалността
						и уникалността на всеки клиент. Нашата екипна страст към
						технологиите и дизайна гарантира, че твоят сайт ще бъде нещо
						изключително.
					</p>
					<p>
						За нас, уебсайтовете не са просто код и графика. Те са истории,
						които разказват за твоята компания. С нашата помощ, твоят уебсайт
						ще бъде уникална визитна картичка, която привлича вниманието на
						посетителите и ги убеждава в уникалността на твоите продукти и
						услуги.
					</p>
					<p>
						Искаш ли уебсайт, който изпъква в морето от стандартни шаблони? Ти
						си на правилното място! Нашите персонализирани решения не само
						отговарят на твоите текущи нужди, но и те подготвят за бъдещето,
						като съчетават иновации и естетика.
					</p>
					<p>
						В Инстант Сълюшънс вярваме, че всеки бизнес заслужава уебсайт, който
						отразява неговата уникалност. Ние не продаваме просто услуги по уеб
						дизайн; предоставяме възможност твоят бизнес да се изрази и да
						създаде трайно впечатление.
					</p>
					<p>
						Създаването на персонализирани уебсайтове е нещо, което правим с
						любов и внимание. Нашата цел е не просто да доставим продукт, а да
						създадем изживяване. Довери ни се със задачата да направим твоя
						уебсайт уникален, елегантен и напълно в съответствие с твоите
						очаквания.
					</p>
				</div>
				<div className="d-flex justify-content-center mt-4">
					<Link to={"/contact-us"}>
						<button className="btn-lg my-2">Свържете се с нас</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
